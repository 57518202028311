//banner
.banner {
	max-height: 500px;
	// height: 500px;
    // overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &-homepage {
    	background-color: $bgGrey;
    	display: flex;
    	flex-direction: column;
    	margin-top: 40px;

    	@media alla and ( max-width: 767px ) {
    		margin-top: 20px;
    	}

    	.banner {
			max-height: 500px;
			height: 360px;
    		margin: 0 auto 46px;
		}

		.swiper-pagination-bullet {
			opacity: .25;
			background: #7e818c;
		}

		.swiper-pagination-bullet-active {
			background: #535766;
			opacity: .75;
		}

		.swiper-container {
			padding-bottom: 33px;
			background-color: $bgGrey;

			@media all and ( max-width: 767px ) {
				padding-bottom: 25px;
			}
		}

		.swiper-container-horizontal > .swiper-pagination-bullets {
			bottom: 0;
		}

		.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
			margin: 0 6px;
		}
    }

    .image_sec {
    	width: 100%;
    	position: relative;

    	&.bgColor_sec {
    		padding: 30px 0;
    	}
    }

    &Small {
    	.image_sec {
			max-height: 300px;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			background-position: center; 
			
			img {
				opacity: 0;
			}	
    	}
    }
}

.adSection {
    margin-top: 30px;
    margin-bottom: 30px;

	@media all and ( max-width: 767px ) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.InfiniteCarouselArrow {
	width: 50px;
}

.InfiniteCarouselArrow.InfiniteCarouselArrowNext {
    right: 30px;
}

.InfiniteCarouselDot {
    width: auto;
    padding: 0;
}

.adSection .banners {
	// max-width: 980px;
    margin: 0 auto;
}

@media all and ( min-width: 1200px ) {
	.banner {
	    &-homepage {
	    	.banner {
				max-height: 500px;
				height: 500px;
	    		margin: 0 auto 46px;
			}
	    }

	    .supplierPage & {
			max-height: 500px;
			// height: 500px;

			.image_sec {
				height: 100%;
				max-height: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				position: relative;
				background-position: center;
			}
	    }
	}
}

.bannercontent {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;

	h1 {
		color: $graywhite;
		font-weight: 400;
		font-size: 2.75rem;
	}

	p {
		font-size: 1.25rem;
		font-weight: 300;
		color: $graywhite;
	}

	.wrapper {
		@media all and ( max-width: 767px ) {
			background-color: transparent;
		}
	}
}

.searchBar {
	margin: 377px auto 42px;
	height: 40px;
	width: 100%;
	display: flex;

	.search_wrapper {
		max-width: 840px;
		width: 100%;
		margin: 0 auto;

		.search {
			&-btn {
				width: 80px;
				// top: 4px;
				// right: 4px;
			}

			&_input {
				width: calc( 100% - 50px );
				border-color: rgba( $white, 0.8 );
				background-color: rgba( $white, 0.9 );
				padding-right: 40px;

				&-wrapper {
					// background-color: rgba( $white, 0.4 );
					// padding: 4px;
					border-radius: 4px;
					// border: 1px solid $black;
					box-shadow: 0 0 10px 4px rgba( $black, 0.2 );
				}
			}
		}
	}
}

.browser_is_safari {
	.searchBar {
		display: block;
	}
}

@media all and ( max-width: 1199px ) {
	.searchBar {
		margin-top: 243px;
		width: 90%;
	}
}

@media all and ( max-width: 767px ) {
	.searchBar {
		margin-top: 270px;
		margin-bottom: 15px;
	}

	.banner-homepage {
		margin-top: 20px;
		margin-top: 0;

		.banner {
			height: 240px;
			position: relative;

			.mobile-search + .search_wrapper {
				padding: 0;
				background-color: transparent;

				button.search-btn {
					right: 0;
					top: 0;
					width: 40px;
				}
			}

			.searchBar {
				margin-top: 20px;
				width: 	100%;

				.search_wrapper .search_input {
					width: calc( 100% - 10px );
				}
			}

			.adSection {
				position: absolute;	
				top: calc( 100% + 10px );
			}
		}

		+ .category_wrapper {
			padding-top: 0;
			margin-top: -15px;
		}
	}

	section.adSection {
		margin-top: 10px;
		margin-bottom: 0;
		margin: 0;
		padding: 0;
	}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}

.PopupBox .modalHeader button.close.btn.btn-primary {
	width: 18px;
}
.appBlockLogo{ width:100px; }

.navbar {
	background: $white;
	border: none;
	box-shadow: none;
	padding: 0;
	font-family: $font-family-second;
	font-weight: 500;
	font-style: normal;

	@media all and ( min-width: 1400px ) {
		padding: 0 10px;
	}

	.wrapper_1200 {
		@include padding-l-r( 15px );
	}

	&-fixed-top {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		box-shadow: 0 0 10px rgba(0,0,0,.08);
		min-height: 50px;
	}

	.container-head {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;	
	}

	&-brand {
		align-content: center;
		height: 100%;
		margin-right: 40px;
		max-width: 200px;
		padding: 0;
		max-height: 77px;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.nav_right,
.navbar-brand,
.nav_left {
	display: inline-flex;
	align-items: center;
	height: 100%;

	&.language-box {
		float: right;
		display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;
	    padding-left: 20px;
	}
}

@media all and ( min-width: 768px ) {
	.navbar-brand {
		max-width: 152px;
	}
}

.nav {
	&_right {
		a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
		}
	}

	&_categories {
		.dropdown-categories .btn {
			padding-right: 25px;
			padding-top: 0;
			padding-bottom: 0;
			border: none;
		}

		.dropdown-menu {
			min-width: 250px;

			.dropright {
				position: relative;

				&:after {
					content: '\e826';
					position: absolute;
					font-size: 10px;
					top: 10px;
					right: 10px;
					font-family: 'fontello';
					font-display: swap;
				}
			}
		}

		.dropdown-categories {
			button {
				padding-left: 0;

				&:focus {
					border: 1px solid transparent;
				}
			}
		}
	}

	&-bar {
		.nav_link > .link {	
			margin-right: 35px;
			color: $text-color-2;
			font-size: 14px;
			text-decoration: none;
			padding-bottom: 25px;
			padding-top: 8px;
			border-bottom: 3px solid $white;
			display: inline-block;
			font-weight: 700;
			text-transform: uppercase;

			&.active,
			&:hover {
				color: $black;
				border-bottom: 3px solid rgba(var(--primary-color), 1);
			}
		}
	}
}

.nav-bar {
	&-single-page {
		margin-bottom: 20px;
		padding-bottom: 10px;
		border-bottom : 1px solid $border-color;

		@media all and ( max-width: 767px ) {
			margin-bottom: 10px;
		}

		.link {
			font-family: $font-family-heebo;
			font-weight: 500;
			font-size: .875rem;
			color: $black;
			padding-right: 20px;
			text-transform: uppercase;
			opacity: 0.7;

			@media all and ( max-width: 767px) {
				display: inline-block !important;
			}

			&.active-link {
				color: rgba(var(--primary-color), 1);
				opacity: 1;
				
			}

			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
	}
}

.cat {
	&_text {
		display: inline-block;
		color: $text-color-2;
		font-size: 1.2rem;
		font-weight: 400;
		font-family: $font-family-second;
		font-weight: 500;
		border-bottom: 3px solid #fff;

		&:hover {
			color: $black;
		}

		@include media-breakpoint-up ( xl ) {
			padding: 0;
			padding-right: 4px;
			padding-bottom: 20px;
		}

		padding: 0;
	}

	&_arrow {
		font-size: 10px;
		display: none;
	}
}

.nav_link {
	display: inline-block;

	&-detail {
		position: absolute;
		top: 100%;
		left: 0;
		opacity: 0;
		background-color: $white;
		padding: 30px 10px 10px;
		display: none;
		flex-wrap: wrap;
		box-shadow: inset 0 0 8px rgba(0,0,0,.1);
		width: 100%;

		.link {
			display: inline-block;
			flex: 0 0 20%;
    		max-width: 20%;
    		color: $text-color-2;
			font-weight: 400;
			line-height: 1;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;

			&:hover {
				font-weight: 600;
				text-decoration: none;
				color: rgba(var(--primary-color), 1 );
			}

			&:last-child {
				font-weight: 700;
				color: rgba(var(--primary-color), 1);

				&:hover {
					text-decoration: underline;
				}
			}

			&:first-child {
				color: rgba(var(--secondary-color), 1);
				font-weight: 700;

				&:hover {
					color: #000;
				}
			}
		}
	}

	&:hover {
		.nav_link-detail {
			opacity: 1;
			display: flex;
		}

		+ .blackoverlay {
			display: block;
		}		
	}


}

.wrapperdiv {
	display: flex;
	height: 100%;

	button {
		display: flex;
		align-items: center;
	}
}

.nav_left-menu,
.nav_categories-all {
	color: $black1;	
	padding: 0 0 0 10px;

	@include media-breakpoint-up ( xl ) {
		padding: 0 6px 0 16px;
	}

	font-size: 15px;
	font-weight: 400;
}

.nav_categories {
	.dropdown-toggle:after {
		display: none;
	}

	.dropdown-menu {
		.cat-viewall {
			padding: 10px 10px 10px 12px;

			a.btn {
				background-color: transparent;
				color: #000;
				padding: 2px 10px;
				font-size: 11px;
				border-radius: 20px !important;
				border: 1px solid #000;
			}
		}
	}

	.btn {
		padding-right: 20px;
	}

	.dropdown:hover {
		.cat_text {
			border-bottom: 3px solid rgba(var(--primary-color), 1);
		}
	}
}

.cat_dash {
	width: 18px;
	height: 2px;
	border-radius: 5px;
	background-color:#6e6e6e;
	position: relative;
	top: -2px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;

	&:before,
	&:after {
		content: '';
		height: 2px;
		border-radius: 5px;
		width: 18px;
		background-color:#6e6e6e;
		position: absolute;
		left: 0;
	}

	&:before {
		top: -6px;
	}

	&:after {
		bottom: -6px;
	}
}

.nav_right {
	font-size: 0.875rem;
	font-weight: 600;

	a {
		padding: 0 2px;
		color: $text-color-2;
		font-size: 0.875rem;

		@include media-breakpoint-up ( xl ) {
			padding: 0 4px;
		}
	}

	.icon {
		&-phone {
			font-size: 16px;
			transform: rotate( 20deg );
			display: inline-block;
		}

		&-cart,
		&-heart {
			font-size: 20px;
		}
	}
}

.nav_right-mob svg,
.nav_right-wishlist svg,
.nav_right-cart svg {
	position: relative;
}

.nav_right-mob svg {
	top:-3px;
}

.nav_right-wishlist svg {
	top: -2px;
}

.nav_right-cart svg {
	top:-3px;
}

.user-profile-wrap {
	color: $text-color-2;
	font-size: 0.875rem;
	font-weight: 500;
	display: flex;
	align-items: center;
	padding: 0;
	padding-left: 20px;

	.loggedIn {		
		padding: 8px 0 0 15px;
		display: block;
		width: 100%;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
	}

	.dropdown-controller,
	.dropdown {
		height: 100%;
	}

	.btn-user {
		@media all and ( min-width: 768px ) {
			margin-top: -8px;
		}
	}
}

.user-profile {
	display: inline-flex;
	align-items: center;
	margin: 0;
	padding: 0;
	margin-top: 2px;

	@include media-breakpoint-up ( lg ) {
		margin-left: 5px;
		padding: 4px 5px;
	}

	&-div {
		display: flex;
		margin-right: 0;

		button {
			padding-right: 0;
			padding-left: 0;
		}
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&-wrap {
		position: relative;
		padding-right: 0;

		.dropdown {
			&-menu {
				left: auto;
				right: 0;
				margin-top: 0;

				.btn {
					width: 100%;
					border-radius: 4px;
				}

				&.show {
					opacity: 0;
					z-index: -1;
					visibility: hidden;
				}
			}

			&-usermenu {
				background-color: #474747;
				padding: 20px 20px 12px;
				border-radius: 10px 0 10px 10px;
				margin-top: 0;
				top: 51px;
				min-width: 250px;
				border: 0;

				a {
					color: #fff;
					font-size: 14px;
					font-weight: 300;
					display: block;
					padding-bottom: 10px;
					margin-bottom: 10px;
				}
			}

			&-toggle:after {
				content: "";
				position: absolute;
				right: 0;
				top: 20px;
				margin-left: 4px;
				border-top: 7px solid rgba( 35, 31, 32, 0.24 );
				border-right: 6px solid transparent;
				border-bottom: 0;
				border-left: 6px solid transparent;
				border-radius: 10px;
			}

			.icon-user-wrap {
				margin-left: 0;
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&:hover {
				.dropdown-menu {
					display: block;
					opacity: 1;
					z-index: 1;
					visibility: visible;
				}
			}
		}

		&.show {
			.user-profile {
				background-color: #474747;	
			}

			.dropdown-toggle::after {
				border-top: 7px solid #fff;
			}
		}

		.icon-user {
			&-wrap {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;

				i {
					line-height: 1;

					&:before {
						line-height: 1;
						margin: 0;
					}
				}
			}
		}
	}
}

i.icon_user {
	background: url(https://constant.myntassets.com/web/assets/img/b8817bd3-5993-48e4-9771-92e4e565b5141571898164626-MyntraWeb-Sprite.png);
	background-size: 1404px 105px;
	width: 20px;
	height: 20px;
	background-position: -298px -56px;

	@media all and ( max-width: 767px ) {
		width: 24px;
		height: 24px;
	}
}

.userimage-div {
	width: 26px;
	height: 26px;
	background-color: #cec8c8;
	border-radius: 30px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;	

	img {
		width: 100%;
	}

	&:after {
		display: none;
	}

	@media all and ( min-width: 768px ) {
		margin: auto;
	}
}

.user-profile-wrap .dropdown-toggle:after {
	display: none;
}

.with-border a {
	border-bottom: 1px solid $black1;	
}

.inputBox {
	&-with-curve {
		border-radius: 10px;
		padding-left: 10px;
	}
}

.search {
	&_wrapper {
		position: relative;
		width: 65%;
		flex: 1;

		button.search-btn {
			//border: none;
			width: 46px;
			border-radius: 0;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			background-color: #f5f5f6;
			justify-content: center;
			align-items: center;
			display: flex;
			padding: 0;

			&:active {
				background-color: var(--primary-color) !important;
				box-shadow: inset 100px 100px 10px rgba( $black, 0.45 );
			}

			span {
				&:before {
					margin: 0;
				}
			}

			&:focus {
				border: 1px solid #f5f5f6;
				border-color: #f5f5f6;
			}
		}

		.search_input {
			width: calc(100% - 30px);
			border-radius: 0;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			padding: 8px 10px 10px;
			padding-right: 15px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			background-color: #f5f5f6;
			border: 1px solid #f5f5f6;
			border-right: none;
			font-size: 13px;

			@media all and ( max-width: 767px ) {
				font-size: 16px;
			}

			&::placeholder {
				text-transform: none;
				font-style: normal;
				color: #696e79;
			}

			&-wrapper {
				flex: 1;
				display: flex;
			}
		}
	}

	&_error {
		border-radius: 4px;
	    box-shadow: 0 0 5px #a91523;
	}
}

.icon_search {
    background-image: url(../images/mobilesearch.svg);
    background-size: 21px;
    background-position: center;
    width: 21px;
    height: 21px;
    transform: scale(0.8);
	display: inline-block;
}

.star-ratings {
	pointer-events: none;
}

.menu_bar {
	.wrapper_1200.menu-right-section {
		display: flex;
		justify-content: flex-end;
	}

	.menu-right-section-div {
		@media all and ( min-width: 768px ) {
			position: relative;
			max-width: 380px;
			width: 100%;
		}

		@media all and ( min-width: 1200px ) {
			margin-right: 10px;
		}
	}
}

//Fornt end design

.btn {
	&-user {
		padding-left: 10px;		
	}
}

.logoutLink {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.navbar-header {
	display: flex;
	align-items: center;
	flex: 1;
	padding-right: 0 !important;
}

.menu {
	&_bar {
		padding-top: 20px;
		display: flex;
		justify-content: space-between;
		flex: 1;
		width: 100%;

		.wrapper_1200 {
			flex: 1;
		}

		.search_wrapper {
			width: 100%;
			display: flex;
		}
	}
}

.text {
	&-cart,
	&-wish {
		@include media-breakpoint-down ( md ) {
			display: none;
		}
	}

	&-phone {
		@include media-breakpoint-down ( md ) {
			font-size: 12px;
		}
	}
}

.cart-count-bubble {
	background: rgba(var(--primary-color), 1);
	border-radius: 30px;
	width: 18px;
	height: 18px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: $white;
	font-size: 10px;
	line-height: 1;
	position: absolute;
    top: 50%;
    right: -10px;
    margin-top: -32px;
}

.profile {
	&_content {
		padding: 30px;

		.btn_wrapper {
			padding-top: 50px;
			padding-bottom: 20px;
		}

		.profile-billing,
		.profile-shipping {
			max-width: 500px;
			width: 100%;

			li {
				margin-bottom: 10px;
			}
		}

		.profile-billing {
			padding-left: 40px;
		}

		.profile-shipping {
			padding-right: 40px;

			[type='checkbox']+label {
				padding-left: 20px;

				&:before {
					box-shadow: inset 0 0 0 1px #b0b0b3;
					width: 14px;
					height: 14px;
				}
			}
		}

		.inputBox {
			@extend .inputBox-with-curve;
		}
	}

	&-image {
		padding-right: 40px;
		width: 250px;

		.img-wrapper {
			width: 170px;
			border-radius: 50%;
			height: 170px;
			border: 5px solid $white;
			box-shadow: 0 0 10px #ccc;
			overflow: hidden;
			position: relative;
		}
	}

	&_list {
		@include media-breakpoint-up ( lg ) {
			width: 700px;
		}	

		ul {
			column-count: 2;
			column-gap: 50px;

			li {
				margin-bottom: 25px;
			}
		}
	}

	&_label,
	&_text {
		display: block;
	}

	&_label {
		font-size: 14px;
	}
}

.h-180 {
	height: 180px;
}

.sidebar_toggle {
	@include media-breakpoint-up ( lg )	{
		display: none;
	}

	width: 30px;
	height: 30px;
	background: #0e2656;
	position: absolute;
	left: 214px;
	text-align: center;

	.cat_dash,
	.cat_dash:after,
	.cat_dash:before {
		background-color: #fff;
	}
}

.show_sidebar {
	&.dashboard {
		.main_container {
			width: calc( 100% - 15px )!important;
		}
	}

	.sidebar {
		width: 10px;
		min-width: 10px;
	}

	.sidebar_toggle {
		left: 10px;
	}

	.sidebar-sticky {
		display: none;
	}

	.cat_dash {
		background-color: transparent;
		
		&:before {
			top: 0px;
			transform: rotate( 45deg );
		}

		&:after {
			bottom: 0px;
			transform: rotate( -45deg );
		}
	}
}

.dropdown {
	&-controller {
		display: flex;
		align-items: center;
		cursor: pointer;
		flex-direction: column;
		justify-content: center;

		.btn {
			border: none;
		}
	}

	&-item {
		&:active {
			background-color: transparent;			
		}

		&[role="button"] {
			background-color: transparent;
			width: 130px;
			margin-top: 5px;
		}
	}
}

.alertmessage {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 30%;
	min-height: 50px;
	background-color: #d4edda;
	border-color: #c3e6cb;
	text-align: center;
    margin: 0 auto;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #155724;
}



.loader,
.loader:before,
.loader:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
}
.loader {
	color: rgba( $black, 0.5 );
	font-size: 10px;
	margin: 5px auto;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;

	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
	}

	&:before {
		left: -3.5em;
		animation-delay: -0.32s;
	}

	&:after {
		left: 3.5em;
	}
}

#LoginPopup {
	background-color: transparent;
}

.switch-language {
	height: 100%;

	&:after {
		content: none;
		width: 30px;
		height: 60px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 0;
		box-shadow: 0 0 3px rgba( $black, 0.5 );
		background-color: $white;
	}

	&:hover {
		.language-wrapper {
			display: inline-flex;
		}
	}

	.language-wrapper {
		border: none;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		display: inline-flex;
		background-color: $white;
		position: relative;
		z-index: 1;
		height: 100%;

		img {
			opacity: 1;
		}

		&.active {
			display: none;
			order: 1;
			pointer-events: none;

			img {
				opacity: 1;
			}
		}
	}

	.to-english {
		img {
			max-width: 22px;
			height: auto;
		}
	}

	.to-nepali {
		img {
			max-height: 15px;
			width: auto;
		}
	}
}

.loader-wrapper {
	min-height: calc( 100vh - 250px );
	position: relative;
}

#content-loader {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;
	border: 6px solid #aaa;
	border-radius: 50%;
	border-top: 6px solid #bbb;
	border-right: 6px solid #ccc;
 	border-bottom: 6px solid #ddd;
	width: 60px;
	height: 60px;
	margin-top: -30px;
	margin-left: -30px;
	animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

/* Add animation to "page content" */
.animate-bottom {
	position: relative;
	-webkit-animation-name: animatebottom;
	-webkit-animation-duration: 1s;
	animation-name: animatebottom;
	animation-duration: 1s
}

@-webkit-keyframes animatebottom {
	from { bottom:-100px; opacity:0 } 
	to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
	from{ bottom:-100px; opacity:0 } 
	to{ bottom:0; opacity:1 }
}

@-webkit-keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}

@media all and ( min-width: 768px ) {
	.user-profile-wrap {
		display: inline-flex;
		align-items: center;
		height: 100%;
		flex-direction: column;
		justify-content: center;
	}
}

@include media-breakpoint-down (md) {
	.cart-count-bubble {
	    width: 14px;
	    height: 14px;
	}
}

.mobile_menu {
	display: none;

	button {
		background:transparent;
	}
}

@media all and ( max-width: 767px ) {
	.mobile_menu {
		display: inline-flex;

		button {
			padding: 0;
			padding-right: 10px;

			.cat_dash {
				margin-right: 0;

				&:before {
					top: -6px;
				}

				&:after {
					bottom: -6px;
				}
			}
		}
	}

	.nav_left {
		display: none;
	}

	.suppliers-header {
	    flex-direction: column;
	}

	.suppliers-contents .catTitle {
	    order: 2;
	    margin-top: 15px;
	}

	.suppliers-contents .search_wrapper {
		max-width: 100%;
		width: 100%;
	}

	.nav-bar.header-nav .link:not(#becomeaseller) {
		color: $text-color;

		&.active {
			color: $black;
			font-weight: 700;
		}
	}

	.text-profile {
		display: none;
	}
}

.user-profile-div {
	.mapicon {
		display: flex;
	    align-items: center;
	    height: 100%;

		@media all and (min-width: 768px) {
			button.button {
				background-color: #fff;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}

		button.button {
			background-image: none;
			display: flex;
	    	flex-direction: column;
	    	align-items: center;
	    	padding-top: 0;
	    	padding-left: 0;	
	    	padding-right: 0;	
	    	height: 100%;
	    	justify-content: center;
		}
	}	
}

.search_wrapper.show {
	+ .mapicon {
		display: block;
	}
}

.menu_bar {
	.search_wrapper {
		.mapicon {
			left: 1px;
			bottom: 1px;
			height: 34px;
		}

		.search_input {
			font-size: 16px;
			border-right: none;
			border-radius: 4px 0 0 4px;

			+ button.search-btn {
				border-left: none;
				border-radius: 0 4px 4px 0;
			}

			&:focus {
				border-color: #eaeaec;
				border-right: none;
				background-color: #fff;

				+ button.search-btn {
					border: 1px solid #eaeaec;
					border-left: none;
					background-color: #fff;
				}
			}
		}
	}

	@media all and (min-width: 768px) {
		.wrapper_1200 {
			position: relative;
		}
	}
}

.icon-location {
	background-image: url(../images/placeholder.svg );
	display: inline-block;
	width: 18px;
	height: 18px;
}

.IE_11 .navbar-brand img {
	width: 152px;
}

.IE_11 .fb_r1 .fb_c2 > div {
	height: 34px;
}

.flagText {
	font-weight: 400;
}

span.flag-img {
    height: 18px;
    position: relative;
    top: -3px;
}

.popup-content {
	.signUp {
		.mapicon {
			right: 20px;
	    	top: 30px;
	    	position: absolute;

			button.button {
				height: auto;

				.fa + span {
					display: none;
				}
			}
		}
	}
}

.blackoverlay {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    display: none;
}

//For mobile views
@media all and ( max-width: 767px ) {
	.menu-label {
		display: none;
	}

	.nav_right.language-box,
	.userpages .nav_right-cart {
		padding-left: 10px;
	}

	.icon-cart:before {
		margin: 0;
	}

	.cart-count-bubble {
		right: -10px;
		margin-top: -18px;
	}

	.userimage-div {
		margin-top: 0;
	}
}

@media all and ( max-width: 840px ) {
	.navbar {
		&-brand {
		    margin-right: 15px;
		}

		&-header {
			padding-right: 5px;
		}
	}
}

@media all and ( max-width: 767px ) {
	.cart-count-bubble {
	    right: -10px;
	    margin-top: -20px;
	}

	.mapicon {
	    padding-left: 10px;
	}

	.nav_right.language-box {
		padding-left: 14px;
	}

	.alertmessage {
		max-width: 100%;
		padding: 10px 10px 0 10px;
	}

	body {
		padding-top: 50px;
		background-color: #F5F5F5;

	    &.noScroll {
	    	overflow: hidden;
	    }
	}

	.popup-content {
	    max-width: 90%;
    	max-height: calc( 100vh - 30px);
	}

	.navbar-brand {
	    max-width: 150px;
	    margin-right: 20px;
	}

	.menu_bar {
	    padding-top: 0;
	}

	.container-head .text-phone, .container-head .user-profile-wrap, 
	.menu_bar .nav-bar, .dropdown .cat_dash {
		display: none;
	}

	.cat_dash {
		margin-right: 10px;
    	margin-top: 4px;
    	cursor: pointer;
	}

	#drawers {
	    position: fixed;
	    top: 0;
	    left: -110%;
	    z-index: 110;
	    width: 75%;
	    height: 100%;
	    overflow: auto;
	    background-color: $white;
	    transition: left .3s;
	}

	#drawers.navShow {
	    left: 0;
	}

	#mask {
	    display: none;
		position: absolute;
		position: fixed;
	    top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	    z-index: 109;
	    width: 100%;
	    height: 100%;
	    background: rgba(0,0,0,.2);

	    &.navShow {
	    	display: block;
	    }

	    &.navHide {
	    	display: none;
	    }
	}

	.navClose {
		position: absolute;
		top: 0;
		right: 0;
		width: 24px;
		height: 24px;
		text-align: center;
		color: #fff;
		background-color: #ffffff;
		border-bottom-left-radius: 10px;
		cursor: pointer;

		.icon-cancel {
			font-size: 0.75rem;
			line-height: 1;
			position: relative;
			top: -2px;
			color: black;
		}
	}

	.nav-bar.header-nav {
	    padding-top: 10px;
		padding-left: 10px;
	}

	.nav-bar .link {
	    display: block;
	    padding-left: 5px;
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;
		font-weight: 600;
		color: #545353;
	}

	

	.user-profile-wrap {
		margin: 30px 10px 10px;
	    // min-height: 60px;
	    display: flex;
		padding: 0;
		font-size: 16px;
		flex-direction: column;
    	align-items: flex-start;
		
		.userimage-div {
			margin-right: 10px;
		}
	}

	.user-profile-wrap .button#LoginPopup {
		display: flex; 
		padding: 0;
		align-items: center;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		color: rgba( var( --secondary-color ), 1 );
		padding: 10px 10px 0px 10px;
		width: 100%;
		background-color: #effefe;
		justify-content: center;

		.icon-user-wrap {
			margin-right: 10px;
		}
	}

	.userimage-div {
	    width: 50px;
	    height: 50px;
	    background-color: #e6dcdc;
	}

	span.login-text {
	    padding-top: 0;
		display: inline-block;
		letter-spacing: 0.5px;
		position: relative;
		top: -5px;
	}

	.logoutLink {	    
		display: flex; 
		align-items: center;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		color: rgba( var( --secondary-color ), 1 );
		width: 100%;
		background-color: #effefe;
		justify-content: center;
		padding: 5px 10px;

		&:hover {
			color: rgba( var( --secondary-color ), 1 );
			text-decoration: none;
		}
	}

	.user-profile-wrap .icon-user-wrap {
	    margin-left: 0;
	    width: 26px;
	    height: 26px;
	    padding-top: 2px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    padding: 0;
	    background-color: $white;
	    font-size: 25px;
	    border-radius: 50px;
	}

	.user-profile-wrap .dropdown-controller {
	    flex-direction: column-reverse;
	    color: #fff;
	    padding-top: 0;
	    padding-bottom: 5px;
	    padding-left: 10px;
	    align-items: flex-start;
	}

	.btn-user {
	    padding-left: 0;
	}

	.user-profile-wrap .dropdown-menu {
	    opacity: 1!important;
	    z-index: 1!important;
	    visibility: visible!important;
	    position: relative!important;
	    transform: none!important;
	    margin: 0!important;
	    width: 100%!important;
	    will-change: auto!important;
	    border-radius: 0!important;
	    border: none;
	    width: 100%;
	    display: block;
	    border-bottom: 1px solid #ded9d9;
	}

	.dropdown-item {
	    font-size: 16px;
	    padding-left: 10px;
	}

	.user-profile-wrap .dropdown {
		width: 100%;
	}

	.menu_bar .wrapper_1200 {
		display: flex;
		justify-content: flex-end;
		margin-right: 0;
		padding-right: 0;

		.mobile-search {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.mobile-search {
	    &.show {
	    	color: rgba(var(--primary-color), 1);
	    }
	}

	.mobile-search + .search_wrapper {
		top: 36px;
		left: 0;
		position: absolute;
		background-color: $white;
		width: 100%;
		padding: 15px;
		transition: all 0.1s linear;

		button.search-btn {
			right: 15px;
			top: 15px;
		}

		&.hide {			
			opacity: 0;
			visibility: hidden;
			z-index: -1;
		}

		&.show {
			opacity: 1;
			visibility: visible;
			z-index: 1;	
			box-shadow: 0 2px 4px rgba( 0, 0, 0, 0.2 );		
		} 

		.mapicon {
			left: 16px;
			bottom: 16px;
		}
	}	

	//banner
	.mainpage .image_sec {
		img {
			opacity: 0;
		}

		background-size: cover;
		height: 230px;
	}

	.prev, .next {
		display: none !important;
	}

	.category_wrapper {
	    padding-bottom: 40px;
	    padding-top: 10px;
	}

	.three-col-footer-icon .icon:before {
		margin-left: 0;
	}

	.banner-homepage .mobile-search {
		display: none;

		& + .search_wrapper.hide {
			opacity: 1;
			visibility: visible;
			z-index: 1;
			position: relative;
			top: 0
		}
	}

	footer > div {
	    padding-left: 0;
	}

	.category_wrapper {
		.breadcrumb-section {
			flex-direction: column;
			margin-top: 20px;
			align-items: flex-start;
		}

		.breadcrumb-div {
		    padding-bottom: 20px;
		}
	}

	.cartPage, .thankyouPage {
		padding-top: 0;
	    padding-bottom: 0;
	}

	.page-link {
	    padding: 5px 9px;
	}

	.singlebtnSection {
		display: block;
	}

	input.button-minus {
		padding-right: 5px;
	}

	input.button-plus {
		padding-left: 5px;
	}

	.singlebtnSectionLeft {
		padding-bottom: 10px;
	}

	.page-list {
		&.category_wrapper {
			.breadcrumb {
				&-section {
					margin-bottom: 60px;
					margin-top: 0;
					position: relative;
					justify-content: flex-start;
					align-items: flex-start;
					height: auto;

					@media all and ( max-width: 767px ) {
						margin-bottom: 0;
					}

					.section_sort {
						position: absolute;
						right: 0;
						bottom: -60px
					}
				}

				&-div {
					padding-bottom: 5px;
					padding-top: 8px;
					padding-bottom: 0;
				}
			}

			.categoryList {
				padding: 0 5px;
			}
		}

		&.category_listing_page {
			.breadcrumb {
				&-section {
					padding-bottom: 10px;
				}
			}
		}
	}

	.miniTitle {
		font-size: 12px;
	}

	.menu_bar {
		.mapicon {
			display: none;
		}
	}

	.signUp {
		.mapicon button.button {
			top: 54px;
		}
	}

	.popup-content {
		max-width: 100%;
		max-height: calc( 100vh - 10px );
		overflow: scroll;
		overflow-x: hidden;
		margin-top: 0 !important;
		border-radius: 0!important;

		.modalBody {
			.signUp {
				.btnWrapper {
					margin-top: 0;
				}
			}
		}
	}
}

@media all and ( max-width: 567px ) {
	.image-gallery-thumbnails-wrapper {
	    width: calc(100vw - 50px);
	}

	.suppliers-contents {
	    margin-top: 0;
    	padding: 20px 0 15px;
	}

	.suppliers-header {
	    flex-direction: column;
	}

	.suppliers-contents .catTitle {
	    order: 2;
	    margin-top: 15px;
	}

	.suppliers-contents .search_wrapper {
	    max-width: 100%;
	    width: 100%;
	}

	.category_wrapper-single .col-sm-4 {
	    margin-bottom: 20px;
	}
}

@media all and ( max-width: 375px ) {
	.navbar-brand {
		max-width: 100px;
	}
}

.contact_details{
	color: #231f20;
	font-weight: 600;
	font-size: 14px;
	display: block;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}